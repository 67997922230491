body {
  margin: 0;
  font-family: 'Roboto',sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f2f7;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* font-family: 'Josefin Sans', sans-serif; */
*
{
    font-family:  'Josefin Sans', sans-serif !important;
}

